import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import { Tab, Tabset } from "react-rainbow-components";
import { SecretManageModal } from "../../components/internal/SecretManageModal";
import { SecretCreateModal } from "../../components/internal/SecretCreateModal";
import { listSecrets, listBuildSecrets, deleteSecret, deleteBuildSecret } from "../../api/SecretsService";
import Modal from "react-rainbow-components/components/Modal";

export default function SecretsManagement() {
  const [secrets, setSecrets] = useState(["my-secret", "my-other-secret"]);
  const [buildSecrets, setBuildSecrets] = useState(["my-build-secret", "my-other-build-secret"]);
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = useState("runtime");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSecret, setSelectedSecret] = useState(null);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, secret: null });

  const tabOptions = [
    { label: "Runtime Secrets", name: "runtime" },
    { label: "Build Secrets", name: "build" },
  ];

  // TODO: Replace with actual API calls
  useEffect(() => {
    const fetchSecrets = async () => {
      setIsLoading(true);
          listSecrets({
            onFinish: (data) => {
              setSecrets(data);
            },
            onError: (error) => {
              console.error("Error fetching runtime secrets:", error);
              // TODO: Add error handling
            }
          });
          listBuildSecrets({
            onFinish: (data) => {
              setBuildSecrets(data);
            },
            onError: (error) => {
              console.error("Error fetching build secrets:", error);
              // TODO: Add error handling
            }
          });
    };

    fetchSecrets();
    setIsLoading(false);
  }, []);

  const handleAddSecret = (type) => {
    setIsCreateModalOpen(true);
  };

  const handleSecretCreated = async () => {
    // Refresh the secrets list
    const fetchSecrets = async () => {
      setIsLoading(true);
      listSecrets({
        onFinish: (data) => {
          setSecrets(data);
        },
        onError: (error) => {
          console.error("Error fetching runtime secrets:", error);
        }
      });
      listBuildSecrets({
        onFinish: (data) => {
          setBuildSecrets(data);
        },
        onError: (error) => {
          console.error("Error fetching build secrets:", error);
        }
      });
      setIsLoading(false);
    };
    await fetchSecrets();
  };

  const handleManageSecret = (secret) => {
    setSelectedSecret(secret);
    setIsManageModalOpen(true);
  };

  const handleSaveSecret = async (secretId, newValue) => {
    // TODO: Implement API call to update secret
    console.log('Updating secret:', secretId, 'with new value:', newValue);
    // After successful update, refresh the secrets list
    // await fetchSecrets();
  };

  const handleDeleteSecret = (secret) => {
    setDeleteConfirmation({ isOpen: true, secret });
  };

  const confirmDelete = async () => {
    const secret = deleteConfirmation.secret;
    const deleteFunction = selectedTab === 'runtime' ? deleteSecret : deleteBuildSecret;

    deleteFunction({
      secretName: secret,
      onFinish: async () => {
        await handleSecretCreated(); // Reuse existing refresh logic
        setDeleteConfirmation({ isOpen: false, secret: null });
      },
      onError: (error) => {
        console.error(`Error deleting ${selectedTab} secret:`, error);
        // TODO: Add error handling
      }
    });
  };

  const RenderSecretTable = ({data}) => {
    return (
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {data
          .filter((s) => s.toLowerCase().includes(search.toLowerCase()))
          .map((secret) => (
            <li 
              key={secret}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px',
                margin: '8px 0',
                borderRadius: '4px',
                border: '1px solid #e0e0e0'
              }}
            >
              <span>{secret}</span>
              <div>
                <Button
                  variant="neutral"
                  label="Manage"
                  onClick={() => handleManageSecret(secret)}
                  style={{ marginRight: '8px' }}
                />
                <Button
                  variant="destructive"
                  label="Delete"
                  onClick={() => handleDeleteSecret(secret)}
                />
              </div>
            </li>
          ))}
        {isLoading && <li>Loading...</li>}
      </ul>
    );
  }

  return (
    <div>
      <Navbar enforceInternalOnly={true} />
      <div style={{ padding: "20px" }}>
        <div style={{ 
            // display: "flex", justifyContent: "space-between", marginBottom: "20px" 
            }}>
          <Input
            label="Secret Search"
            placeholder="Search for secrets"
            type="search"
            style={{ width: 300 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            variant="brand"
            label={`Add ${selectedTab === 'runtime' ? 'Runtime' : 'Build'} Secret`}
            onClick={() => handleAddSecret(selectedTab)}
          />
        </div>

        <Tabset
          id="secrets-tabs"
          onSelect={(_, name) => {
            setSelectedTab(name)
            setSearch('')
          }}
          selectedTabName={selectedTab}
          variant="card"
        >
          {tabOptions.map((tab) => (
            <Tab
              key={tab.name}
              name={tab.name}
              label={tab.label}
            
            />
          ))}
        </Tabset>
        <RenderSecretTable data={(
                selectedTab === 'runtime' ? secrets : buildSecrets
                )} />
      </div>
      <SecretManageModal
        isOpen={isManageModalOpen}
        onClose={() => setIsManageModalOpen(false)}
        secret={selectedSecret}
        onSave={handleSaveSecret}
        isBuildSecret={selectedTab !== 'runtime'}
      />
      <SecretCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        isBuildSecret={selectedTab !== 'runtime'}
        onSecretCreated={handleSecretCreated}
      />
      <Modal
        isOpen={deleteConfirmation.isOpen}
        onRequestClose={() => setDeleteConfirmation({ isOpen: false, secret: null })}
        title="Confirm Delete"
        footer={
          <div className="rainbow-flex rainbow-justify_end">
            <Button
              label="Cancel"
              variant="neutral"
              onClick={() => setDeleteConfirmation({ isOpen: false, secret: null })}
              className="rainbow-m-right_small"
            />
            <Button
              label="Delete"
              variant="destructive"
              onClick={confirmDelete}
            />
          </div>
        }
      >
        <p>Are you sure you want to delete the secret "{deleteConfirmation.secret}"?</p>
        <p>This action cannot be undone.</p>
      </Modal>
    </div>
  );
} 