import { useState } from "react";
import Modal from "react-rainbow-components/components/Modal";
import Button from "react-rainbow-components/components/Button";
import { TextField } from "@mui/material";
import { createSecret, updateBuildSecret } from "../../api/SecretsService";

export const SecretCreateModal = ({ isOpen, onClose, isBuildSecret, onSecretCreated }) => {
  const [secretName, setSecretName] = useState("");
  const [secretValue, setSecretValue] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (isBuildSecret) {
        updateBuildSecret({
          secretName,
          secretValue,
          onFinish: () => {
            onSecretCreated();
            handleClose();
          },
          onError: (error) => {
            console.error("Failed to create build secret:", error);
            // TODO: Add error handling
          }
        });
      } else {
        createSecret({
          secretName,
          secretValue,
          onFinish: () => {
            onSecretCreated();
            handleClose();
          },
          onError: (error) => {
            console.error("Failed to create runtime secret:", error);
            // TODO: Add error handling
          }
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleClose = () => {
    setSecretName("");
    setSecretValue("");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      title={`Create New ${isBuildSecret ? 'Build' : 'Runtime'} Secret`}
      footer={
        <div className="rainbow-flex rainbow-justify_end">
          <Button
            label="Cancel"
            variant="neutral"
            onClick={handleClose}
            className="rainbow-m-right_small"
          />
          <Button
            label="Create"
            variant="brand"
            onClick={handleSave}
            isLoading={isSaving}
            disabled={!secretName || !secretValue}
          />
        </div>
      }
    >
      <div style={{ padding: "20px" }}>
        <div style={{ marginBottom: "20px" }}>
          <TextField
            label="Secret Name"
            value={secretName}
            onChange={(e) => setSecretName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Secret Value"
            value={secretValue}
            onChange={(e) => setSecretValue(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </div>
      </div>
    </Modal>
  );
}; 