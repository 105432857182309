import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

// const API_HOST = "http://localhost:8080/local-api-tunnel";

export const listRedeemableFeatures = async ({
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.get(
          `${API_HOST}/core-services/v1/redeemable-features`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const createRedeemableFeature = async ({
  minimumUserId,
  featureId,
  featureName,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.post(
          `${API_HOST}/core-services/v1/redeemable-features`,
          {
            minimumUserId,
            featureId,
            featureName,
          },
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const deleteRedeemableFeature = async ({
  featureId,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.delete(
          `${API_HOST}/core-services/v1/redeemable-features/${featureId}`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const updateRedeemableFeature = async ({
  featureId,
  minimumUserId,
  featureName,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.put(
          `${API_HOST}/core-services/v1/redeemable-features`,
          {
            featureId,
            minimumUserId,
            featureName,
          },
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
}; 