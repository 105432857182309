import { useEffect, useState } from "react";
import Modal from "react-rainbow-components/components/Modal";
import Button from "react-rainbow-components/components/Button";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import { Spinner } from "react-rainbow-components";
import { readBuildSecret, readSecret, updateBuildSecret, updateSecret } from "../../api/SecretsService";

export const SecretManageModal = ({ isOpen, onClose, secret, onSave, isBuildSecret }) => {
  const defaultSecretValue = "********";
  const [showSecret, setShowSecret] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [secretValue, setSecretValue] = useState(defaultSecretValue);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
    //   await onSave(secret, secretValue);
      if (isBuildSecret) {
        updateBuildSecret({ secretName: secret, secretValue: secretValue, onFinish: (data) => {
            // console.log("Secret updated:", data);
            setIsEditing(false);
        }, onError: (error) => {
            console.error("Failed to update secret:", error);
        }});
      } else {
        updateSecret({ secretName: secret, secretValue: secretValue, onFinish: (data) => {
            // console.log("Secret updated:", data);
            setIsEditing(false);
        }, onError: (error) => {
            console.error("Failed to update secret:", error);
        }});
      }


      setIsEditing(false);
    } catch (error) {
      console.error("Failed to save secret:", error);
      // TODO: Add error handling
    } finally {
      setIsSaving(false);
    }
  };


  const handleRead = async () => {
    setIsLoading(true);

    if (isBuildSecret) {
        readBuildSecret({ secretName: secret , onFinish: (data) => {
            setSecretValue(data.value);
            setIsLoading(false);
        }, onError: (error) => {
            console.error("Failed to read secret:", error);
            setIsLoading(false);
        }});
    } else {
        readSecret({ secretName: secret , onFinish: (data) => {
            setSecretValue(data.value);
            setIsLoading(false);
        }, onError: (error) => {
        console.error("Failed to read secret:", error);
        setIsLoading(false);
        }});
    }
  };

  useEffect(() => {
    console.log("Clearing secret value");
    setSecretValue(defaultSecretValue);
    setShowSecret(false);
  }, [secret]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={`Manage Secret: ${secret}`}
      footer={
        <div className="rainbow-flex rainbow-justify_end">
          <Button
            label="Cancel"
            variant="neutral"
            onClick={onClose}
            className="rainbow-m-right_small"
          />
          {isEditing && (
            <Button
              label="Save"
              variant="brand"
              onClick={handleSave}
              isLoading={isSaving}
            />
          )}
        </div>
      }
    >
      <div style={{ padding: "20px" }}>
        <div style={{ marginBottom: "20px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextField
              label="Secret Value" 
              value={showSecret ? secretValue : defaultSecretValue}
              disabled={!isEditing}
              onChange={(e) => setSecretValue(e.target.value)}
              style={{ flex: 1, minHeight: '100px' }}
              rows={4}
              multiline
            />
            <IconButton
              onClick={() => {
                setShowSecret(!showSecret);
                if (!showSecret) {
                  handleRead();
                }
            }}
              style={{ marginTop: "20px" }}
            >
              {isLoading ? <Spinner /> :
              (!showSecret ? <VisibilityOff /> : <Visibility />)
              }
            </IconButton>
            <IconButton
              onClick={() => {setIsEditing(!isEditing); setShowSecret(true)}}
              style={{ marginTop: "20px" }}
              color={isEditing ? "primary" : "default"}
            >
              <EditIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <p style={{ fontSize: "14px", color: "#666" }}>
            <strong>Environment:</strong> {secret?.environment}
          </p>
          <p style={{ fontSize: "14px", color: "#666" }}>
            <strong>Last Modified:</strong> {secret?.lastModified}
          </p>
        </div>
      </div>
    </Modal>
  );
}; 