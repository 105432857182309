import axios from "axios";
import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

// const API_HOST = "http://localhost:8080/local-api-tunnel"

export const listSecrets = async ({
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.get(
          `${API_HOST}/core-services/v1/secrets`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};


export const listBuildSecrets = async ({
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.get(
          `${API_HOST}/core-services/v1/secrets/build`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const readSecret = async ({
  secretName,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.get(
          `${API_HOST}/core-services/v1/secrets/${secretName}`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const readBuildSecret = async ({
  secretName,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.get(
          // Build secrets are paths, so we need to query by id
          `${API_HOST}/core-services/v1/secrets/build/by-id?id=${secretName}`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const updateSecret = async ({
  secretName,
  secretValue,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.put(
          `${API_HOST}/core-services/v1/secrets/${secretName}`,
          {
            value: secretValue,
          },
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const createSecret = async ({
  secretName,
  secretValue,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.post(
          `${API_HOST}/core-services/v1/secrets/${secretName}`,
          {
            value: secretValue,
          },
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const updateBuildSecret = async ({
  secretName,
  secretValue,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.put(
          `${API_HOST}/core-services/v1/secrets/build/by-id?id=${secretName}`,
          {
            value: secretValue,
          },
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const deleteSecret = async ({
  secretName,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.delete(
          `${API_HOST}/core-services/v1/secrets/${secretName}`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

export const deleteBuildSecret = async ({
  secretName,
  onFinish,
  onError,
}) => {
  try {
    onFinish(
      (
        await axios.delete(
          `${API_HOST}/core-services/v1/secrets/build/by-id?id=${secretName}`,
          {
            headers: {
              ebtc: getBaseToken(),
              estc: getSecureToken(),
            },
          }
        )
      ).data
    );
  } catch (e) {
    console.error(e);
    onError(e);
  }
};

