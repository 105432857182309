import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Box,
} from '@mui/material';
import { Alert, Snackbar } from '@mui/material';
import {
  listRedeemableFeatures,
  createRedeemableFeature,
  deleteRedeemableFeature,
  updateRedeemableFeature,
} from '../api/RedeemableFeaturesService';
import Navbar from '../components/Navbar';
import { Edit as EditIcon } from '@mui/icons-material';

const RedeemableFeatures = () => {
  const [features, setFeatures] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [formData, setFormData] = useState({
    featureId: '',
    featureName: '',
    minimumUserId: '',
  });
  const [editMode, setEditMode] = useState(false);

  const loadFeatures = () => {
    setLoading(true);
    listRedeemableFeatures({
      onFinish: (data) => {
        setFeatures(data);
        setLoading(false);
      },
      onError: () => {
        showSnackbar('Failed to load redeemable features', 'error');
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    loadFeatures();
  }, []);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editMode) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const handleCreate = () => {
    createRedeemableFeature({
      ...formData,
      minimumUserId: parseInt(formData.minimumUserId, 10),
      onFinish: () => {
        showSnackbar('Feature created successfully');
        closeModal();
        loadFeatures();
      },
      onError: () => {
        showSnackbar('Failed to create feature', 'error');
      },
    });
  };

  const handleUpdate = () => {
    updateRedeemableFeature({
      ...formData,
      minimumUserId: parseInt(formData.minimumUserId, 10),
      onFinish: () => {
        showSnackbar('Feature updated successfully');
        closeModal();
        loadFeatures();
      },
      onError: () => {
        showSnackbar('Failed to update feature', 'error');
      },
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({ featureId: '', featureName: '', minimumUserId: '' });
    setEditMode(false);
  };

  const openEditModal = (feature) => {
    setFormData({
      featureId: feature.featureId,
      featureName: feature.featureName,
      minimumUserId: feature.minimumUserId.toString(),
    });
    setEditMode(true);
    setIsModalOpen(true);
  };

  const handleDelete = (featureId) => {
    deleteRedeemableFeature({
      featureId,
      onFinish: () => {
        showSnackbar('Feature deleted successfully');
        loadFeatures();
      },
      onError: () => {
        showSnackbar('Failed to delete feature', 'error');
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div>
        <Navbar enforceInternalOnly={true} />
    <Box sx={{ padding: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
        >
          Create New Feature
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Feature ID</TableCell>
              <TableCell>Feature Name</TableCell>
              <TableCell>Minimum User ID</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              features.map((feature) => (
                <TableRow key={feature.featureId}>
                  <TableCell>{feature.featureId}</TableCell>
                  <TableCell>{feature.featureName}</TableCell>
                  <TableCell>{feature.minimumUserId}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => openEditModal(feature)}
                        startIcon={<EditIcon />}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDelete(feature.featureId)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isModalOpen} onClose={closeModal}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            {editMode ? 'Edit Redeemable Feature' : 'Create Redeemable Feature'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
              <TextField
                name="featureId"
                label="Feature ID"
                value={formData.featureId}
                onChange={handleInputChange}
                required
                fullWidth
                disabled={editMode}
              />
              <TextField
                name="featureName"
                label="Feature Name"
                value={formData.featureName}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                name="minimumUserId"
                label="Minimum User ID"
                type="number"
                value={formData.minimumUserId}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              {editMode ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
    </div>
  );
};

export default RedeemableFeatures; 